import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  loader$: Subject<boolean> = new Subject();
  constructor() {}
  hideShowLoader(flag: boolean) {
    this.loader$.next(flag);
  }
}
